import {http} from "./utils";
import {useEffect, useState} from "react";
import Markdown from "react-markdown";
// import apiMd from '../md/api.md'
import remarkGfm from 'remark-gfm'
import './name.css'
function App() {
    const markdownText = '# 欢迎\n这是一个简单的Markdown示例 *强调文字*';
    const [val, setVal] = useState('')
    const getMdContext = async()=>{
        const isLegalUser = await http.get('https://jsonplaceholder.typicode.com/todos/1');
        console.log(isLegalUser);
        setVal(isLegalUser);
    }
    //
    useEffect(()=>{
        getMdContext();
    },[])

    const markdown = '# Hi, *Pluto*! ' +
        ' *## dasda*' +
        ' *### dasdafsf*' +
        ' *###### rerer*';

  return (
    <div >
          {/*<h1 className={style.aaaa}>首页</h1>*/}
          <h1 className="aaaa">首页</h1>
          {/*<h1>{val.toArray()}</h1>*/}
        {/*<Markdown>{markdownText}</Markdown>*/}
        {/*<Markdown*/}
        {/*    source={apiMd}*/}
        {/*    escapeHtml={false}*/}
        {/*/>*/}
        <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
    </div>
  );
}

export default App;
