import axios from "axios";
const http = axios.create({
    // baseURL: 'http://localhost:8989',
    // timeout: 2000
})

http.interceptors.response.use( function (response) {
    return response.data;
},error=>{
    alert(error.message)
    console.log(error)
})

export {http}