import {createBrowserRouter} from 'react-router-dom'

import Success from "../page/Success";
import Fail from "../page/Fail";
import App from "../App";



const router = createBrowserRouter([
    {
        path:'/success',
        element: <Success></Success>
    },
    {
        path: '/fail',
        element: <Fail></Fail>
    },
    {
        path: '/',
        element: <App></App>
    }
])

export default router